import React from "react";
import "../styles/global.scss";
import { Divider } from "./Divider";

function getAnchor(a) {
  if (a === null) {
    return;
  }
  return "#" + a.replace(/\s/g, "-").toLowerCase();
}

export default function FoundationPage({
  pageTitle,
  description,
  link1title,
  link1url,
  link2title,
  link2url,
  link3title,
  link3url,
  tocItems,
  children,
}) {
  return (
    <div className="styled-grid gap-space-800">
      <div className="styled-grid">
        {pageTitle != null && (
          <div className="styled-grid gap-space-500">
            <div className="gds-size-900-bold">{pageTitle}</div>
            {description != null && (
              <div
                className="gds-size-600-regular"
                style={{ maxWidth: "var(--gds-layout-600" }}
              >
                {description}
              </div>
            )}
          </div>
        )}

        {link1title != null && (
          <div
            className="d-flex flex-wrap gap-space-300"
            style={{ marginLeft: "-12px" }}
          >
            <a target="_blank" rel="noreferrer" href={link1url}>
              <gds-button
                color="primary"
                background="transparent"
                size="tiny"
                icon-position="right"
                icon="export"
              >
                {link1title}
              </gds-button>
            </a>
            {link2url != null && (
              <a target="_blank" rel="noreferrer" href={link2url}>
                <gds-button
                  color="primary"
                  background="transparent"
                  size="tiny"
                  icon-position="right"
                  icon="export"
                >
                  {link2title}
                </gds-button>
              </a>
            )}
            {link3url != null && (
              <a target="_blank" rel="noreferrer" href={link3url}>
                <gds-button
                  color="primary"
                  background="transparent"
                  size="tiny"
                  icon-position="right"
                  icon="export"
                >
                  {link3title}
                </gds-button>
              </a>
            )}
          </div>
        )}
      </div>
      <Divider />
      <div className="row">
        <div className="col-md order-md-1 order-2 styled-grid gap-space-800 me-space-500 foundation-page markdown-content">
          {children}
        </div>
        {tocItems != null && (
          <div className="col-md-4 col-lg-3 order-md-2 order-1 mb-space-800 table-of-contents">
            <div className="sticky">
              <div className="tabs" columns="1">
                <div
                  className="gds-size-100-bold text-gray-600 mb-space-400"
                  style={{ textTransform: "uppercase" }}
                >
                  Table of Contents
                </div>
                {tocItems.map((item) => (
                  <a
                    href={getAnchor(item)}
                    className={
                      typeof window !== "undefined" &&
                      window.location.href.includes(getAnchor(item) + "-tab")
                        ? "gds-size-400-regular tab-item active"
                        : "gds-size-400-regular tab-item"
                    }
                    id={(getAnchor(item) + "-tab").substring(1)}
                  >
                    {item}
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

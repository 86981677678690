import React from "react";
import { Divider } from "../../components/Divider";
import FoundationPage from "../../components/FoundationPage";
import Layout from "../../components/Layout";

export default function DesignPrinciplesPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Design Principles"
        description="Nielsen's core values are inclusion, courage, and growth. These
        values animate our workplace and the work we do. Inspired by those
        values, these design principles are the foundation of our design
        system. They guide us in making design decisions when building the
        system and our products."
        // link1title="View in Figma"
        // link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/GDS-%2F-Core-Library?node-id=19910%3A516780"
      >
        <React.Fragment>
          <div className="section">
            <h2>Built For Our Users</h2>
            <p className="description">
              Nielsen users have particular needs and our products should
              reflect those needs. We aim to provide modern, efficient, and
              pleasant solutions to help them accomplish their work.
            </p>
          </div>
          <div className="section">
            <h2>Always Accessible</h2>
            <p className="description mt-space-200">
              Accessibility helps everyone and hurts no one. We work to
              integrate accessibility at every level of the stack. This includes
              our brand, content, foundations, components, and patterns.
            </p>
          </div>
          <div className="section">
            <h2>Consistency not Uniformity</h2>
            <p className="description mt-space-200">
              Our goal isn't to make everything the same. It's to create a
              cohesive set of controls and patterns that our users will
              intuitively understand.
            </p>
          </div>
          <div className="section">
            <h2>Inspire Trust & Confidence</h2>
            <p className="description mt-space-200">
              We strive to build the user's trust by consistently providing
              clear and concise navigation, input, content, and actions. When
              needed, our products give informative and helpful feedback to help
              a user with any issue.
            </p>
          </div>
          <div className="section">
            <h2>Strive for Elegance</h2>
            <p className="description mt-space-200">
              The system isn't optimized to be purely beautiful. It's focused on
              being useful and aesthetic. We want our components and patterns to
              be understandable, unobtrusive, thorough, and appropriately
              minimal.
            </p>
          </div>
          <div className="section">
            <h2>Efficient for Designers & Developers</h2>
            <p className="description mt-space-200">
              Creating great products requires designers, developers, and
              product to work closely together. This system is a centralized
              toolbox to help everyone craft excellent Nielsen products.
              Designers and developers should expect this system to be a
              well-documented, comprehensive set of components to help them
              efficiently do their jobs.
            </p>
          </div>
          <Divider />
        </React.Fragment>
      </FoundationPage>
    </Layout>
  );
}
